import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { VideoService } from '../services/video.service';

@Component({
  selector: 'app-embed-video',
  template: `<section id="videoembed" [innerHTML]="embedTag"></section>`,
  styles: [],
})
export class EmbedVideoComponent implements OnInit {
  platform = 'youtube';
  videoId = '';
  embedTag: SafeHtml = '';
  constructor(
    private activatedRoute: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private videoService: VideoService
  ) {}

  getEmbedHtml = () => {
    this.videoService.getVideoEmbed(this.platform, this.videoId).subscribe({
      next: (data) => {
        this.embedTag = this.sanitizer.bypassSecurityTrustHtml(data.embed);
      },
      error: (error) => console.error(error),
    });
  };

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.platform = params['platform'];
      this.videoId = params['id'];

      this.getEmbedHtml();
    });
  }
}
