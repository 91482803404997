import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  imgsrc: string = '/assets/images/logo.png';
  navToggle: boolean = false;

  salesanalyticsSvg: string = '/assets/images/salesanalyticDark.svg';
  salesanalyticsSvgDark: string = '/assets/images/salesanalytics.svg';

  marketSvg: string = '/assets/images/marketintelligence.svg';
  marketDarkSvg: string = '/assets/images/marketintelligencedark.svg';

  subMenuSvg: string = '/assets/images/greaterthan.svg';

  productMappingSvgDark: string = '/assets/images/botoptions_dark.svg';
  productMappingSvg: string = '/assets/images/botoptions.svg';

  salesIntelligenceSvg: string = '/assets/images/salesintelligence.svg';
  salesIntelligenceSvgDark: string = '/assets/images/salesintelligencedark.svg';

  isWidget: boolean = false;

  constructor(
    protected readonly keycloak: KeycloakService,
    public router: Router,
    private route: ActivatedRoute
  ) {}

  logout() {
    this.keycloak.logout('https://dashboard.predigle.com/');
  }

  navtoggle() {
    this.navToggle = !this.navToggle;
    console.log(this.navToggle);
    if (this.navToggle) {
      this.imgsrc = '/assets/images/logocircle.png';
    } else {
      this.imgsrc = '/assets/images/logo.png';
    }
  }

  changeIcon() {
    this.salesanalyticsSvg = '/assets/images/salesanalyticDark.svg';
  }

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      if (params['iswidget'] == 'true') {
        this.isWidget = true;
      }
    });
  }
}
