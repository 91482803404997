import { Component, OnInit, Output } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';

@Component({
  selector: 'app-sales-analytic',
  templateUrl: './sales-analytic.component.html',
  styleUrls: ['./sales-analytic.component.scss'],
})
export class SalesAnalyticComponent implements OnInit {
  userObject: any;
  token: string;
  refreshToken: string;
  roles: any[];

  constructor(protected readonly keycloak: KeycloakService) {}

  async ngOnInit(): Promise<void> {
    this.userObject = await this.keycloak.loadUserProfile();
    this.token = await this.keycloak.getToken();
    this.refreshToken = this.keycloak.getKeycloakInstance().refreshToken;
    this.roles = this.keycloak.getUserRoles();
  }

  async update() {
    await this.keycloak.updateToken(-1).then(async () => {
      this.token = await this.keycloak.getToken();
    });
    this.refreshToken = this.keycloak.getKeycloakInstance().refreshToken;
  }
}
