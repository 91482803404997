<input *ngIf="!isWidget" type="checkbox" id="nav-toggle" (click)='navtoggle()'>
<div *ngIf="!isWidget" class="sidebar">
    <div class="sidebar-brand">
        <img [src]="imgsrc" alt="">
    </div>

    <div class="sidebar-menu">

        <a [routerLink]=" ['/'] ">
            <button class="dashboardhome">
                <img src="/assets/images/homedashboard.svg" alt="">
                <p>Dashboard</p>
            </button>
        </a>

        <div class="bots">

            <div class="sidebar-subtitle">
                <p>My Fabrix</p>
                <hr>
            </div>

            <ul>
                <li>
                    <a class="sidebar-option" [routerLink]=" ['/configuration'] " routerLinkActive="active">
                        <img src="/assets/images/buyerpropensity.png" alt="">
                        <span>Buyer Propensity</span>
                    </a>
                </li>
                <li>
                    <a class="sidebar-option" [routerLink]=" ['/competitivepricing'] " routerLinkActive="active">
                        <img src="/assets/images/competitivepricing.png" alt="">
                        <span>Competitive Pricing</span>
                    </a>
                </li>
                <li>
                    <a class="sidebar-option" href="https://dashboard.predigle.com/video-engagement/"
                        routerLinkActive="active">
                        <img src="/assets/images/customersimilarity.png" alt="">
                        <span>Media Engagement</span>
                    </a>
                </li>
                <li>
                    <a class="sidebar-option" [routerLink]=" ['/oip'] " routerLinkActive="active">
                        <img src="/assets/images/businesssimilarity.png" alt="">
                        <span>Opportunity Intervention</span>
                    </a>
                </li>
            </ul>

        </div>

        <div class="analytics">

            <div class="sidebar-subtitle">
                <p>Analytics</p>
                <hr>
            </div>

            <ul>

                <li>
                    <a class="sidebar-option" [routerLink]=" ['/marketintelligence'] " routerLinkActive="active"
                        #market="routerLinkActive">
                        <img *ngIf="market.isActive" [src]="marketSvg" alt="">
                        <img *ngIf="!market.isActive" [src]="marketDarkSvg" alt="">
                        <span>Market Intelligence</span>
                    </a>
                </li>
                <li>
                    <!-- Sales Analytics Menu with its Sub Menu -->
                    <a class="sidebar-option" [routerLink]="['/salesintelligence']" routerLinkActive="active"
                        #salesIntelligence="routerLinkActive">
                        <img *ngIf="salesIntelligence.isActive" [src]="salesIntelligenceSvg" alt="">
                        <img *ngIf="!salesIntelligence.isActive" [src]="salesIntelligenceSvgDark" alt="">
                        <span>Sales Intelligence</span>
                    </a>

                </li>
                <li>
                    <!-- Sales Analytics Menu with its Sub Menu -->
                    <a class="sidebar-option" [routerLink]="'/precogs'" routerLinkActive="active"
                        #sales="routerLinkActive">
                        <img *ngIf="sales.isActive" [src]="salesanalyticsSvg" alt="">
                        <img *ngIf="!sales.isActive" [src]="salesanalyticsSvgDark" alt="">
                        <span>Sales Analytics</span>
                    </a>

                </li>
            </ul>

        </div>

        <div class="setting">

            <div class="sidebar-subtitle">
                <p>Configurations</p>
                <hr>
            </div>

            <ul>
                <li>
                    <a class="sidebar-option" [routerLink]=" ['/buyerpropensity'] " routerLinkActive="active">
                        <img src="/assets/images/configuration.svg" alt="">
                        <span>Buyer Propensity</span>
                    </a>
                </li>
                <li>
                    <a class="sidebar-option" [routerLink]=" ['/productMapping'] " routerLinkActive="active"
                        #productMapping="routerLinkActive">
                        <img *ngIf="productMapping.isActive" [src]="productMappingSvgDark" alt="">
                        <img *ngIf="!productMapping.isActive" [src]="productMappingSvg" alt="">
                        <span>Competitor Mapping</span>
                    </a>
                </li>
            </ul>

        </div>

        <a>
            <button class="logouthome" (click)='logout()'>Logout</button>
        </a>

    </div>
</div>

<div class="main-content" [ngClass]="{'main-content-widget':isWidget}">
    <header *ngIf="!isWidget">

        <div class="leftside">
            <label for="nav-toggle">
                <img src="/assets/images/hamburger.svg" alt="hamburger">
            </label>

            <button>
                <span>Wagon</span>
                <img src="/assets/images/downarrow.svg" alt="">
            </button>
        </div>

        <div class="rightside">

            <!-- <form>
                <img src="/assets/images/sidenavsearch.svg" alt="">
                <input type="text" name="searchbox" id="searchbox" placeholder="Search">
            </form> -->

            <div class="icons">
                <!-- <img src="/assets/images/gridbox.svg" alt="">
                <img src="/assets/images/bellicon.svg" alt="">
                <img src="/assets/images/settingheader.svg" alt="">
                <img src="/assets/images/americaflag.png" alt=""> -->
                <img src="/assets/images/usericon.png" alt="">
            </div>

        </div>

    </header>

    <main [ngClass]="{'main-widget':isWidget}">
        <router-outlet></router-outlet>
    </main>

</div>