import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-redirectpage',
  templateUrl: './redirectpage.component.html',
  styleUrls: ['./redirectpage.component.scss']
})
export class RedirectpageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
