import { Component, OnInit } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  constructor(protected readonly keycloak: KeycloakService) {}

  logout() {
    this.keycloak.logout('https://dashboard.predigle.com/');
  }

  ngOnInit() {}
}
