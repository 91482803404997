import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';

@Component({
  selector: 'app-keycloak-user',
  templateUrl: './keycloak-user.component.html',
  styleUrls: ['./keycloak-user.component.scss'],
})
export class KeycloakUserComponent implements OnInit {
  userObject: any;
  token: any;
  refreshToken: any;

  constructor(
    protected readonly keycloak: KeycloakService,
    private http: HttpClient
  ) {}

  async ngOnInit(): Promise<void> {
    this.userObject = await this.keycloak.loadUserProfile();
    console.log('User Object :', this.userObject);
    this.token = await this.keycloak.getToken();
    this.refreshToken = this.keycloak.getKeycloakInstance().refreshToken;
    // console.log('Roles:', this.keycloak.getUserRoles());
  }

  logout() {
    this.keycloak.logout('https://dashboard.predigle.com/');
  }

  async update() {
    await this.keycloak.updateToken(-1).then(async () => {
      this.token = await this.keycloak.getToken();
    });
    this.refreshToken = this.keycloak.getKeycloakInstance().refreshToken;
  }
}
