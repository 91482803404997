import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-competitive-pricing',
  templateUrl: './competitive-pricing.component.html',
  styleUrls: ['./competitive-pricing.component.scss'],
})
export class CompetitivePricingComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
