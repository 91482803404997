import { SalesIntelligenceComponent } from './analytics/sales-intelligence/sales-intelligence.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

//Keycloak Auth Guard
import { AuthGuard } from './auth.guard';

//Components
import { HomeComponent } from './pages/home/home.component';
import { KeycloakUserComponent } from './pages/keycloak-user/keycloak-user.component';
import { LogoutComponent } from './pages/logout/logout.component';
import { AdminpageComponent } from './test/adminpage/adminpage.component';
import { RedirectpageComponent } from './test/redirectpage/redirectpage.component';
import { UserpageComponent } from './test/userpage/userpage.component';
import { SalesAnalyticComponent } from './analytics/sales-analytic/sales-analytic.component';
import { MarketIntelligenceComponent } from './analytics/market-intelligence/market-intelligence.component';
import { ProspectsDashbaordComponent } from './analytics/sales-analytic/prospects-dashbaord/prospects-dashbaord.component';
import { CompetitivePricingComponent } from './precogs/competitive-pricing/competitive-pricing.component';
import { ProductMappingComponent } from './configurations/product-mapping/product-mapping.component';
import { PricecompareComponent } from './widgets/pricecompare.component';
import { EmbedVideoComponent } from './widgets/embed-video.component';
import { OpportunityInterventionComponent } from './analytics/opportunity-intervention/opportunity-intervention.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'precogs',
    component: SalesAnalyticComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'salesanalytics/prospectdashboard',
    component: ProspectsDashbaordComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'marketintelligence',
    component: MarketIntelligenceComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'salesintelligence',
    component: SalesIntelligenceComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'oip',
    component: OpportunityInterventionComponent,
    canActivate: [AuthGuard],
  },

  {
    path: 'admin',
    component: AdminpageComponent,
    canActivate: [AuthGuard],
    data: { roles: ['admin'] },
  },
  {
    path: 'user',
    component: UserpageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'accessdenied',
    component: UserpageComponent,
    canActivate: [AuthGuard],
  },
  //Precogs
  {
    path: 'competitivepricing',
    component: CompetitivePricingComponent,
    canActivate: [AuthGuard],
  },
  //Configurations
  {
    path: 'productMapping',
    component: ProductMappingComponent,
    canActivate: [AuthGuard],
  },
  //Widgets
  {
    path: 'pricecompare',
    component: PricecompareComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'videoembed',
    component: EmbedVideoComponent,
    canActivate: [AuthGuard],
  },
  {
    //Testing Page
    path: 'testPage-keycloak',
    component: KeycloakUserComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'logout',
    component: LogoutComponent,
  },

  // Access Denied Routes
  {
    path: 'businesssimilarity',
    component: UserpageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'competitivepricing',
    component: UserpageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'buyerpropensity',
    component: UserpageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'prospectgrooming',
    component: UserpageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'prospectproximity',
    component: UserpageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'financial',
    component: UserpageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'performance',
    component: UserpageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'healthcheck',
    component: UserpageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'aitrainer',
    component: UserpageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'flows',
    component: UserpageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'botoptions',
    component: UserpageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'configuration',
    component: UserpageComponent,
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
