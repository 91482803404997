import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-pricecompare',
  template: `<price-compare-widget [sku_id]="sku_id"></price-compare-widget>`,
  styles: [],
})
export class PricecompareComponent implements OnInit {
  sku_id: string = '';
  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.sku_id = params['id'];
    });
  }
}
