<div class="userdetails">
    <h1>User Details :</h1>
    <p *ngFor="let user of userObject | keyvalue">
        <span class="key">{{user.key}}</span> : <span class="value">{{user.value}}</span>
    </p>

    <h1>User Role : </h1>
    <p>
        <!-- <span>{{userroles}}</span> -->
    </p>

    <div class="tokens">
        <p><span>Token : </span>{{token}}</p>
        <p><span>refreshToken : </span>{{refreshToken}}</p>
    </div>

    <div class="buttons">
        <button style="margin-right: 20px;" (click)="update()">Update Token</button>
        <button (click)="logout()">Log Out</button>
    </div>
</div>