import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { VideoEmbed } from './../models/video-embed.model';

@Injectable({
  providedIn: 'root',
})
export class VideoService {
  constructor(private http: HttpClient) {}

  getVideoEmbed = (platform: string, id: string) => {
    let params = new HttpParams().set('platform', platform).set('id', id);

    return this.http.get<VideoEmbed>(`${environment.videoEntity}/embedLink`, {
      params,
    });
  };
}
