import { KeycloakConfig } from 'keycloak-js';

let keycloakConf: KeycloakConfig = {
  clientId: 'sdb', // Your `clientId` from Keycloak Admin Console
  realm: 'salesanalyticsdashboard', // Your `realm` from Keycloak Admin Console
  url: 'https://dashboard.predigle.com/auth', // URL of your keycloak installation till `/auth`
};

export const environment = {
  production: false,
  keycloak: keycloakConf,
  videoEntity: 'http://localhost:8004',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
