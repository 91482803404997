import {
  APP_INITIALIZER,
  CUSTOM_ELEMENTS_SCHEMA,
  NgModule,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

//Keycloak Imports
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';

//Environment Class
import { environment } from 'src/environments/environment';

//Material Ui
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';

//Components and modules
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LogoutComponent } from './pages/logout/logout.component';
import { HomeComponent } from './pages/home/home.component';
import { AdminpageComponent } from './test/adminpage/adminpage.component';
import { UserpageComponent } from './test/userpage/userpage.component';
import { RedirectpageComponent } from './test/redirectpage/redirectpage.component';
import { KeycloakUserComponent } from './pages/keycloak-user/keycloak-user.component';
import { SalesAnalyticComponent } from './analytics/sales-analytic/sales-analytic.component';
import { MarketIntelligenceComponent } from './analytics/market-intelligence/market-intelligence.component';
import { ProspectsDashbaordComponent } from './analytics/sales-analytic/prospects-dashbaord/prospects-dashbaord.component';
import { CompetitivePricingComponent } from './precogs/competitive-pricing/competitive-pricing.component';
import { ProductMappingComponent } from './configurations/product-mapping/product-mapping.component';
import { SalesIntelligenceComponent } from './analytics/sales-intelligence/sales-intelligence.component';
import { PricecompareComponent } from './widgets/pricecompare.component';
import {MatIconModule} from '@angular/material/icon';
import { EmbedVideoComponent } from './widgets/embed-video.component';
import { OpportunityInterventionComponent } from './analytics/opportunity-intervention/opportunity-intervention.component';

function initializeKeyCloak(keycloak: KeycloakService) {
  return () =>
    keycloak.init({
      config: environment.keycloak,
      loadUserProfileAtStartUp: true,

      initOptions: {
        onLoad: 'login-required',
        checkLoginIframe: false,
      },
      enableBearerInterceptor: true,
    });
}

@NgModule({
  declarations: [
    AppComponent,
    LogoutComponent,
    HomeComponent,
    AdminpageComponent,
    UserpageComponent,
    RedirectpageComponent,
    KeycloakUserComponent,
    SalesAnalyticComponent,
    MarketIntelligenceComponent,
    ProspectsDashbaordComponent,
    CompetitivePricingComponent,
    ProductMappingComponent,
    SalesIntelligenceComponent,
    PricecompareComponent,
    EmbedVideoComponent,
    OpportunityInterventionComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    KeycloakAngularModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatInputModule,
    MatIconModule

  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeyCloak,
      multi: true,
      deps: [KeycloakService],
    },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
