import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-opportunity-intervention',
  templateUrl: './opportunity-intervention.component.html',
  styleUrls: ['./opportunity-intervention.component.scss'],
})
export class OpportunityInterventionComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
